import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { XtrasService } from '../../services/xtras.service';
import { UserModel } from '../../services/user-model';
import { CommonModule } from '@angular/common';
import { ButtonCapYeiComponent } from '../../components/button/button.component';
import { Router } from '@angular/router';
import { AuthService, UserType } from '../../services/auth.service';
import { Observable } from 'rxjs';
import { HttpClientProvider } from '../../services/http-client';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from '../../pipes/filter';
import { SearchDirective } from '../../directives/search.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsProvider } from '../../services/settings';
import { RegisterProfileModal } from '../register-profile/register-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomSubjectsService } from '../../services/custom-subjects.service';
import { getEnvironment } from '../../environment/environment';

@Component({
  selector: 'profile-settings',
  templateUrl: './profile-settings.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonCapYeiComponent,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {

  user$: Observable<UserType>;
  filterProfiles:string = '';

  idProfile: any = null;
  oldIdProfile: any = null;

  @Input() hasPreview: boolean = true;
  @Input() column: boolean = true;
  @Input() space: boolean = true;

  @Output() updateData = new EventEmitter();

  env = getEnvironment();

  constructor(
    public xServices: XtrasService,
    public userModel: UserModel,
    private router: Router,
    private authService: AuthService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private dialog: MatDialog,
    private cSService: CustomSubjectsService,
  ) {
    this.user$ = this.authService.user$;
    this.user$.subscribe(data => {
      this.idProfile = data?.membershipCurrent?.id;
      this.oldIdProfile = data?.membershipCurrent?.id;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  toLogin() {
    this.router.navigateByUrl('/auth');
  }

  submitForm() {
    if(this.idProfile) {
      this.load.loadingShow();
      this.http.getRequest(SettingsProvider.getUrl(`change-current-membership/${this.idProfile}`)).subscribe({
        next:(result:any) => {
          console.log(result);
          if(result['status']) {
            this.load.dismiss();
            this.updateData.emit();
            this.cSService.changeProfileValue = true;
            this.authService.getUserByToken().subscribe();
          } else {
            this.load.dismiss();
            this.xServices.getErrorApi(result, true, '/');
          }
        },
        error: (error: any) => {
          this.load.dismiss();
          this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
        }
      });
    } else {
      alert('Debe seleccionar un perfil');
    }
  }

  registerProfie() {
    if(this.env.type == 'club-bolivar' || this.env.type == 'club-colocolo' || this.env.type == 'udechile' || this.env.type == 'ucatolica') {
      this.router.navigateByUrl('/register-profile');
    } else {
      const dialogRef = this.dialog.open(RegisterProfileModal, {
        width: '800px',
        disableClose: false,
        autoFocus: false,
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(result);
        if (result && result['data']) {
  
        }
      });
    }
  }
}
