<div class="profile__settings w-full {{ !column ? 'md:flex md:flex-wrap md:gap-4' : '' }}" [ngClass]="space ? 'p-8' : ''">
    <ng-container *ngIf="user$ | async as _user; else elseAuth">
        @if (hasPreview) {
            <div class="border rounded-xl p-4 card__ps text-center current__profile-card-ps {{ !column ? 'md:flex-1' : '' }}">
                <h5 class="title__ps">Perfil actual</h5>
                @if (_user.membershipCurrent) {
                    <div class="h-2 w-full"></div>
                    <img [src]="_user.membershipCurrent.image ?? env.imgLogo" alt="User" class="w-auto block max-w-full mx-auto max-h-[300px]">
                    <div class="h-2 w-full"></div>
                    <h5 class="current__profile-ps" [title]="_user.membershipCurrent.name">{{ _user.membershipCurrent.name }}</h5>
                    <div class="h-2 w-full"></div>
                    <div class="max-w-full inline-block border rounded p-2 text-sm leading-[normal]">
                        {{ _user.membershipCurrent.ci_number ?? '-' }}&nbsp;
                        @if (env.country === 'bo') {
                            {{ _user.membershipCurrent.ci_expedition_basic ?? '' }}
                        }
                    </div>
                }
            </div>
            <div class="current__profile-card-ps-space h-4 w-full {{ !column ? 'md:hidden' : '' }}"></div>
        }
        <div *ngIf="env.multipleProfiles" class="border rounded-xl p-4 card__ps {{ !column ? 'md:flex-1' : '' }}">
            <div class="input_group item__transparent !pt-0">
                <label for="profile_data" class="">Cambiar perfil</label>
                <mat-select name="profile_id" id="profile_data" [(ngModel)]="idProfile" class="custom__mat-select" [value]="_user.membershipCurrent?.id">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterProfiles = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    @if (env.type == 'the-strongest') {
                        <mat-option *ngFor="let item of _user.memberships | filter: filterProfiles : 'name'" [value]="item.id" >{{ item.name }}{{ item.ci_number ? (' - '+item.ci_number) : '' }}</mat-option>
                    } @else {
                        <mat-option *ngFor="let item of _user.memberships | filter: filterProfiles : 'name'" [value]="item.id" >{{ item.name }}</mat-option>
                    }
                </mat-select>
            </div>
            <button-cy (onPress)="submitForm()" [disabled]="(idProfile == oldIdProfile)" addClass="w-full btn__second btn__change-profile">Cambiar perfil</button-cy>
            <div class="h-4 w-full"></div>
            <div class="w-full">
                <button-cy (onPress)="registerProfie()" addClass="w-full btn__primary btn__register-profile">Registrar perfil</button-cy>
            </div>
        </div>
    </ng-container>
    <ng-template #elseAuth>
        <h5 class="font__title md:text-2xl text-lg">Inicie sesión</h5>
        <div class="h-8 w-full"></div>
        <img [src]="env.imgLogo" [alt]="env.title " class="w-auto block max-w-full mx-auto">
        <div class="h-8 w-full"></div>
        <button-cy (onPress)="toLogin()" addClass="w-full btn__primary font__title">Iniciar sesión</button-cy>
    </ng-template>
</div>